@import "./bootstrap/bootstrap";

h1,
h2,
h3,
h4,
h5 {
    @include font-size($h2-font-size);
    text-align: center;
    margin-top: map-get($spacers,  5);
}

main {
    padding: map-get($spacers,  5) 0 map-get($spacers,  5) 0;
}

#summary {
    img {
        border-radius: 50%;
        margin-bottom: map-get($spacers,  3);
    }

    h1 {
        @include font-size($h4-font-size);
        text-align: left;
        margin: 0;

        span {
            display: block;

            &:last-child {
                color: $text-muted;
                @include font-size($small-font-size);
                font-weight: $font-weight-normal;
            }
        }
    }


    h2 {
        @include font-size($h5-font-size);
        text-align: left;
        margin-top: map-get($spacers,  3);
    }

    ul {
        padding-left: map-get($spacers,  3);
    }
}

// mobile styles
@include media-breakpoint-down(md) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: map-get($spacers,  4);
    }

    main {
        padding: map-get($spacers,  3) 0 map-get($spacers,  5) 0;
    }

    #summary {
        img {
            height: 74px;
            width: 74px;
            display: block;
            margin-left: auto;
        }

        h2,
        h3,
        h4,
        h5 {
            text-align: center;
            @include font-size($h2-font-size);
            margin-top: map-get($spacers,  4);
        }
    }
}

@import 'portfolio';
@import 'quarantine-pass-system';
@import 'faq';
@import 'code-sample';
@import 'footer';
