#faq {
    text-align: center;

    .col-md-6 {
        margin-bottom: map-get($spacers, 3);
    }
}

// mobile styles
@include media-breakpoint-down(md) {
    #faq {
        .col-md-6 {
            margin-bottom: map-get($spacers, 2);
        }
    }
}
