footer {
    background-color: $dark;
    padding: map-get($spacers, 5) 0 map-get($spacers, 5) 0;
    text-align: center;
    color: $light;

    h2 {
        @include sr-only();
    }

    ul {
        margin: map-get($spacers, 0);
    }

    .in-memory {
        color: $text-muted;
        @include font-size($small-font-size);
        font-weight: $font-weight-normal;
    }

    a {
        color: $light;

        @include hover-focus() {
            color: darken($light, $emphasized-link-hover-darken-percentage);
        }
    }
}
