#quarantine-pass-system {
    h1,
    h2,
    h3,
    h4,
    h5 {
        text-indent: initial;
        margin-top: map-get($spacers,  5);
        display: block;
    }
}