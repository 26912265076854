#portfolio {
    text-indent: map-get($spacers,  5);

    h1,
    h2,
    h3,
    h4,
    h5 {
        text-indent: initial;
        margin-top: 0;
        display: none;
    }
}

// mobile styles
@include media-breakpoint-down(md) {
    #portfolio {
        text-indent: map-get($spacers,  4);

        h1,
        h2,
        h3,
        h4,
        h5 {
            text-indent: initial;
            margin-top: map-get($spacers,  4);
            display: block;
        }
    }
}
